import React from 'react';
import { Box, Button, Flex, Link, Image, Spacer } from '@chakra-ui/react';
import Discord from "./assets/social-media-icons/discord.svg";
import Opensea from "./assets/social-media-icons/opensea-logo.svg";
import Twitter from "./assets/social-media-icons/twitter-logo.svg";
import Logo from "./assets/social-media-icons/Untitled.png";

const NavBar = ({ accounts, setAccounts }) => {
    const isConnected = Boolean(accounts[0]);

    return (
        
        <Flex justify="space-between" align="center" padding="0" >

            {/* icons  */}

            <Link href='/' isExternal>
                <Image src={Logo} width={50} height={50} position="relative" left="0" top="0" />
                </Link>

        

            <Flex  justify="space-around"  padding="0 0">

            <Link href='https://twitter.com/NovemberNFT' isExternal>
                <Image src={Twitter} boxSize="44px" margin="0 4px" position="relative"   />
                </Link>
                
                <Link href='https://opensea.io/collection/novemnft' isExternal>
                <Image src={Opensea} boxSize="44px" margin="0 4px" position="relative" />
                </Link>

                <Link href='https://etherscan.io/address/0x335729929eadff1dd44a861dd915d4fc2df30c5d' isExternal>
                {/* <Image src={Discord} pointer-events="auto" boxSize="44px" margin="0 4px" position="relative" /> */}
                </Link>
                
            </Flex>
            
        </Flex>
        
        
    )
};


export default NavBar;